import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Root from '../views/Root.vue'

Vue.use(VueRouter)

  const routes = [
  // {
  //   path: '/',
  //   redirect: 'fr'
  // },
      {
        path: '/:lang/',
        name: 'Root',
        component: Home
      },
      {
    path: '/:lang/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/:lang',
    component: Root,
    children: [
      // {
      //   path: '',
      //   name: 'Root',
      //   component: Home
      // },
      // {
      //   path: 'home',
      //   name: 'Home',
      //   component: Home
      // },
     
      {
        path: 'Agricultural',
        name: 'Agricultural',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/Agricultural.vue')
        }
      },
      {
        path: 'Application',
        name: 'Application',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/Application.vue')
        }
      },
      {
        path: 'C175',
        name: 'C175',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/C175.vue')
        }
      },
      {
        path: 'C300',
        name: 'C300',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/C300.vue')
        }
      },
      {
        path: 'C400L',
        name: 'C400L',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/C400L.vue')
        }
      },
      {
        path: 'C475',
        redirect:{  name: 'C500' }
      },
      {
        path: 'C500',
        name: 'C500',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/C500.vue')
        }
      },
      {
        path: 'Company',
        name: 'Company',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/Company.vue')
        }
      },
      {
        path: 'Contact',
        name: 'Contact',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/Contact.vue')
        }
      },
      {
        path: 'Register',
        name: 'Register',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/Register.vue')
        }
      },
      {
        path: 'Dfci',
        name: 'Dfci',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/Dfci.vue')
        }
      },
      {
        path: 'Ecological',
        name: 'Ecological',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/Ecological.vue')
        }
      },
      {
        path: 'Energy',
        name: 'Energy',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/Energy.vue')
        }
      },
      {
        path: 'Forestry',
        name: 'Forestry',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/Forestry.vue')
        }
      },
      {
        path: 'Grinding_stumps',
        name: 'Grinding_stumps',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/Grinding_stumps.vue')
        }
      },
      {
        path: 'gestion',
        name: 'gestion',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/Gestion.vue')
        }
      },
     
      {
        path: 'Oil_industry',
        name: 'Oil_industry',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/Oil_industry.vue')
        }
      },
      {
        path: 'Product',
        name: 'Product',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/Product.vue')
        }
      },
      {
        path: 'Recreative',
        name: 'Recreative',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/Recreative.vue')
        }
      },
      {
        path: 'Rehab_nat',
        name: 'Rehab_nat',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/rehab.vue')
        }
      },
      {
        path: 'Residential_commercial',
        name: 'Residential_commercial',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/Residential_commercial.vue')
        }
      },
      {
        path: 'Road_network',
        name: 'Road_network',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/Road_network.vue')
        }
      },
      {
        path: 'S250',
        name: 'S250',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/S250.vue')
        }
      },
      {
        path: 'S400',
        name: 'S400',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/S400.vue')
        }
      },
      {
        path: 'policy',
        name: 'policy',
        component: function () {
          return import(/* webpackChunkName: "about" */ '../views/policy.vue')
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },  
  base: process.env.BASE_URL,
  routes
})

export default router
