import { render, staticRenderFns } from "./Home.vue?vue&type=template&id=683969dd&scoped=true"
import script from "./Home.vue?vue&type=script&lang=js"
export * from "./Home.vue?vue&type=script&lang=js"
import style0 from "./Home.vue?vue&type=style&index=0&id=683969dd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "683969dd",
  null
  
)

/* custom blocks */
import block0 from "./Home.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)
import block1 from "../../public/Resources/I18N/Screen.Home.json?vue&type=custom&index=1&blockType=i18n&issuerPath=D%3A%5CDevelopment%5CVSTS.Git%5CCMI%5CSpec_2.0%5CSolutions%5CWWW%5Csrc%5Cviews%5CHome.vue&external"
if (typeof block1 === 'function') block1(component)
import block2 from "../../public/Resources/I18N/Shared.Application.json?vue&type=custom&index=2&blockType=i18n&issuerPath=D%3A%5CDevelopment%5CVSTS.Git%5CCMI%5CSpec_2.0%5CSolutions%5CWWW%5Csrc%5Cviews%5CHome.vue&external"
if (typeof block2 === 'function') block2(component)

export default component.exports