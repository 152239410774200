import Vue from 'vue'
import App from './App.vue'
import router from './router'
//import VueMaterial from 'vue-material'
//import Vuelidate from 'vuelidate'
import VueI18n from 'vue-i18n'

import $ from 'jquery'

import $event from './event.js'


//import 'vue-material/dist/vue-material.min.css'
//import 'vue-material/dist/theme/default.css'

Vue.config.productionTip = false;
Vue.config.lang = 'en';
Vue.config.fallbackLang = 'en';

//Vue.use(VueMaterial);
//Vue.use(Vuelidate);

Vue.use(VueI18n)

// event
window.$App = {
  name: "CMI!",
  event: $event,
}; 

// Ready translated locale messages
const messages = {
  en: {
    message: {
      hello: 'hello world',
      welcome: 'Welcome to Your Vue.js App',
      about: 'This is an about page'
    }
  },
  fr: {
    message: {
      hello: 'Bonjour le monde',
      welcome: 'Bienvenue dans votre application Vue.js',
      about: 'Voici la page a propos'
    }
  }
}

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'en', // set locale
  fallbackLocale : 'en',
  messages, // set locale messages
})


router.beforeEach((to, from, next) => {

  const lang = to.params.lang;

  if ( !['en','fr'].includes(lang) ) {
    let url = '/' + i18n.locale + to.fullPath
  //  console.log(url);
    return next(url);
  }

  if ( i18n.locale !== lang ) {
    i18n.locale = lang;
  }

  return next();

  // const { locale } = to.lang
  // loadLocaleMessagesAsync(locale).then(() => {
  // })

  next()
})




new Vue({
  router,
  i18n,
  render: function (h) { return h(App) }
}).$mount('#app')



