<template>
  <div>
    <header>
      <div class="grid-pad grid">
        <div class="logo index_hide"><a href="/" ><img v-bind:src="$t('logo')" alt="" title="" /></a></div>
          <div class="menu-top">
              <div class="col-top"></div>
                  <div class="col-top-right txt-l zonesocial">
                <a class="social" href="https://www.linkedin.com/company/cmi-mulching/" target="_blank"><img  src="/Resources/Images/in.png" alt="" title="" width="21" /></a>
                   <a class="social"  href="https://www.instagram.com/explore/tags/cmimulching/?hl=fr-ca" target="_blank"><img src="/Resources/Images/instagramme.png" alt="" title="" width="21" /></a>
                   <a href="https://www.facebook.com/CMITHEMULCHINGSPECIALISTS/" target="_blank"><img src="/Resources/Images/facebook.png" alt="" title="" width="21" /></a>
                  </div>

                 <div class="col-top-right txt-r vw12 extranet"><a v-bind:href="$t('link_extranet')" target="_blank" >{{$t('extranet')}}</a><br /></div>
                 <div class="col-top-right txt-r vw12 langue"><router-link to="/fr">FR</router-link>&nbsp;&nbsp;|&nbsp;&nbsp;<router-link to="/en">EN</router-link></div>
                </div>

          <div class="wrapnav">
            <nav id="hamnav" class="indexNav">

                <label for="hamburger" class="hamburger-icon">
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                </label>
                <input type="checkbox" id="hamburger"/>

                <div class="main_menu vw12" id="hamitems">
                         <ul>
                          <!-- <a href="/company">{{$t('company')}}</a> -->
                          <li><router-link to="/company">{{$t('menu.company')}}</router-link></li>
                          <li><router-link to="/application">{{$t('menu.application')}}</router-link></li>
                          <li><router-link to="/product">{{$t('menu.products')}}</router-link></li>
                          <li><router-link to="/ecological">{{$t('menu.ecological')}}</router-link></li>
                          <li><router-link to="/contact">{{$t('menu.contact_us')}}</router-link></li>
                      </ul>
                </div>
              </nav>
  </div>

        </div>
   </header>

    <div class="col-1-1 header_index index_bg">
      <div class="grid rel">
        <div class="logo_index"><router-link to="/"> <img v-bind:src="$t('logo')" alt="" title="" /></router-link></div>
        <div class="header_txt_index vw38">{{$t('title')}}</div>
      </div>
    </div>

    <div class="col-1-1">
      <div class="  flex">
        <div class="col-1-2 mobile-col-1-1 col-flex rel bienmobil">
          <div class="center txt_bienvenue">
            <span class="bienvenue">{{$t('welcome')}}</span><br />
            <p v-html="$t('welcome.text')"></p>

          </div>
          </div>
            <div class="col-1-2 mobile-col-1-1 col-flex rel"><img style="display: block;" class="full" src="/Resources/Images/solution.jpg" alt="" />
              <div class="center solut"><router-link to="/ecological" v-html="$t('ecological')"></router-link></div>
            </div>
      </div>
     </div>

     <div class="rel col-1-1">
      <div class="cmi_vertical">
        <img style="display: block;" src="/Resources/Images/cmi_vertical.png" alt="" />
      </div>
  </div>
   <div class="col-1-1 grey">
    <div class="grid grid-pad justpush">

      <h1>{{$t('shared.title')}}</h1>

      <section class="slider">

        <router-link to="/Dfci">
          <img src="/Resources/Images/applications/ligne_coupe-feux.jpg" alt="" class="" >
          <div class="application_thumb_etiquette2 application_thumb_etiquette1_m application_thumb_etiquette_mobile  hide w100" v-html="$t('shared.dfci')"></div>
     </router-link>

     <router-link to="/Oil_industry">
      <img src="/Resources/Images/applications/industrie_petroliere.jpg" alt="" class="" >
      <div class="application_thumb_etiquette2 hide application_thumb_etiquette_mobile  w100" v-html="$t('shared.oil_industry')"></div>
     </router-link>

        <router-link to="/Forestry">
          <img src="/Resources/Images/applications/sylviculture.jpg" alt="" class="" >
          <div class="application_thumb_etiquette2 application_thumb_etiquette2_m  hide w100" v-html="$t('shared.forestry')"></div>
          </router-link>

          <router-link to="/Agricultural">
            <img src="/Resources/Images/applications/agricole.jpg" alt="" class="" >
            <div class="application_thumb_etiquette1 hide w100" v-html="$t('shared.agricultural')"></div>
        </router-link>

        <router-link to="/Energy">
            <img src="/Resources/Images/applications/energie_services_publics.jpg" alt="" class="" >
            <div class="application_thumb_etiquette2 tiquette_mobile  hide w100" v-html="$t('shared.energy')"></div>
       </router-link>

      <router-link to="/Road_network">
                <img src="/Resources/Images/applications/reseau_routier.jpg" alt="" class="" >
                <div class="application_thumb_etiquette2   hide w100" v-html="$t('shared.road_network')"></div>
           </router-link>

           <router-link to="/Grinding_stumps">
        <img src="/Resources/Images/applications/broyage.jpg" alt="" class="" >
        <div class="application_thumb_etiquette1 hide w100 application_thumb_etiquette1_m499 " v-html="$t('shared.grinding_stumps')"></div>
   </router-link>

   <router-link to="/Gestion">
      <img src="/Resources/Images/applications/gestion.jpg" alt="" class="" >
      <div class="application_thumb_etiquette2 hide w100  " v-html="$t('shared.management')"></div>
  </router-link>

   <router-link to="/Residential_commercial">
    <img src="/Resources/Images/applications/commerciale_residentielle.jpg" alt="" class="" >
    <div class="application_thumb_etiquette2 hide w100 " v-html="$t('shared.residential_commercial')"></div>
  </router-link>

        <!--
         <router-link to="/Contact">
            <img src="/Resources/Images/applications/deminage.jpg" alt="" class="" >
            <div class="application_thumb_etiquette2 hide w100 " v-html="$t('shared.deminage')"></div>
       </router-link>-->

           <router-link to="/Recreative">
              <img src="/Resources/Images/applications/recreatif.jpg" alt="" class="" >
              <div class="application_thumb_etiquette2 hide w100 b_etiquette_mobile " v-html="$t('shared.recreative')"></div>
         </router-link>

       <router-link to="/Rehab_nat">
          <img src="/Resources/Images/applications/reabilitation_naturelles.jpg" alt="" class="" >
          <div class="application_thumb_etiquette2 hide w100  " v-html="$t('shared.natural_rehabilitation')"></div>
     </router-link>

      </section>

      <div class="col-1-1 center all_appli vw23"><router-link to="/application">{{$t('shared.link_all_application')}}</router-link></div>
  </div>
  </div>


  <div class="col-1-1 rel"><img style="display: block;" class="full"  src="/Resources/Images/index_produits.jpg" alt=""/>
    <router-link to="/product"><input type="button" class="button_orange w600" v-bind:value="$t('products')"></router-link> </div>
   <div class="col-1-1 rel"><img style="display: block;" class="full"  src="/Resources/Images/contact.jpg" alt=""/>
     <div class="index_contact vw27"><router-link to="/Contact">{{$t('contact_us')}}</router-link></div></div>


  </div>

</template>
<!-- -->
<style scoped  >
  /* index */
  header {height:46px;background-color: rgba(0,0,0, 0.7);position:fixed;color:#fff;z-index:1000;	width: 100%;}
  .index_hide img {display:none;}
  .menu-top  {padding-top:14px;}

  #hamitems {display:none;}
  .sticky {position:fixed;top:0px;left:0px;}

  nav {position:relative;}
  nav.indexNav {position:relative;}

  #hamnav label {position:absolute;top:32px;left:0px;display:inline-block;/*background: #cc0033;*/background-color: rgb(216,28,63);padding:10px;cursor:pointer;}

  .main_menu {position:absolute;top:76px;left:0px;text-align:left;display:block;width:0%;}
  .main_menu ul {list-style-type: none;margin: 0 auto;padding: 0;background-color: rgba(0,0,0, 0.5);width: 230px;padding:10px 0 40px 30px;}
  .main_menu li {display: block;padding-top:30px; }
  .main_menu li a {color: white; text-align: left;text-decoration: none;margin-left:0px;}
  .main_menu li a:hover {text-decoration: underline;}


  @media only screen and (max-width:970px)  {
.application_thumb_etiquette1 {padding:13px;font-size:12px; }
.application_thumb_etiquette2 {padding:6px;font-size:12px; }

  }

@media handheld, only screen and (max-width: 768px)  {
/* header {position:  absolute ;} */
.langue {padding-top:5px;}
.logo {width:20%}
.menu-top  {padding-top:6px;}

.application_thumb_etiquette1 {padding:13px;font-size:12px; }
.application_thumb_etiquette2 {padding:6px;font-size:12px; }
}
  </style>



<i18n>
  {
      "fr": {
        "logo": "/Resources/Images/CMI_LogoFR.png",
        "menu.company": "LA COMPAGNIE",
        "menu.application": "APPLICATIONS",
        "menu.products": "PRODUITS",
        "menu.ecological": "SOLUTION ÉCOLOGIQUE",
        "menu.contact_us": "CONTACTEZ-NOUS",
        "extranet": "Connexion distributeur",
        "link_extranet" : "http://member.cmimulching.com/membreaccueil" 
 
      },
      "en": {
        "logo": "/Resources/Images/CMI_LogoEN.png",
        "menu.company": "THE COMPANY",
        "menu.application": "APPLICATIONS",
        "menu.products": "PRODUCTS",
        "menu.ecological": "ECOLOGICAL SOLUTION",
        "menu.contact_us": "CONTACT US",
        "extranet": "Dealer login",
        "link_extranet" : "http://member.cmimulching.com/MemberHome" 
      }
    }
</i18n>
<i18n src="../../public/Resources/I18N/Screen.Home.json"></i18n>
<i18n src="../../public/Resources/I18N/Shared.Application.json"></i18n>


<script>
// @ is an alias to /src
// msg="Welcome to Your Vue.js App"
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
        mounted:
            function () {
              window.onscroll = function () { checkSticky(); };

              var navbar = document.getElementById("hamnav");
              var sticky = 0;
              if(navbar){
                sticky = navbar.offsetTop;
              }
              
              function checkSticky() {
                if(navbar){
                if (window.pageYOffset >= sticky) {
                  navbar.classList.add("sticky")
                } else {
                  navbar.classList.remove("sticky");
                }
                  }
              } 

              this.$nextTick(function () {

                  $(".slider").slick({
                                      dots: true,
                                      infinite: true,
                                      speed: 300,
                                      slidesToShow: 4,
                                      slidesToScroll: 4,
                                      autoplay: true,
                                      autoplaySpeed: 4000,
                                      arrows:false,
                                      responsive: [
                                                    { breakpoint: 780, settings: { slidesToShow: 2, slidesToScroll: 2 } }
                                                ]
                                    });
                });

                  // var cssId = 'myCss';  // you could encode the css path itself to generate id..
                // if (!document.getElementById(cssId))
                // {
                //   var head  = document.getElementsByTagName('head')[0];
                //   var link  = document.createElement('link');
                //   link.id   = cssId;
                //   link.rel  = 'stylesheet';
                //   link.type = 'text/css';
                //   link.href = 'http://localhost:8080/App_Themes/Main/Index.css';
                //   link.media = 'all';
                //   var x = head.appendChild(link);
                // }

            }

}
</script>
