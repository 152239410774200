<template>
  <div>
    <headerMain @setLang="setLang" />
    <router-view />
  </div>
  </template>
  <script>
    // @ is an alias to /src
    // msg="Welcome to Your Vue.js App"
    import headerMain from "@/components/Header.vue";
  
    export default {
      name: 'app',
      data: () => ({
        
          }),
      methods: {
        setLang: function (lang) {
          this.$i18n.locale = lang;
          }
      },
          components: {
              "headerMain": headerMain,
          }
    }
    </script>