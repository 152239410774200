
<template>
	<header>
        <div class="grid-pad grid">
          <div class="logo index_hide"><router-link to="/"><img v-bind:src="$t('logo')" alt="" title="" /></router-link></div>
            <div class="menu-top">
                <div class="col-top"></div>
                    <div class="col-top-right txt-l zonesocial">
                  <a class="social" href="https://www.linkedin.com/company/cmi-mulching/" target="_blank"><img  src="/Resources/Images/in.png" alt="" title="" width="21" /></a>
                     <a class="social"  href="https://www.instagram.com/explore/tags/cmimulching/?hl=fr-ca" target="_blank"><img src="/Resources/Images/instagramme.png" alt="" title="" width="21" /></a> 
                     <a href="https://www.facebook.com/CMITHEMULCHINGSPECIALISTS/" target="_blank"><img src="/Resources/Images/facebook.png" alt="" title="" width="21" /></a> 
                    </div>
                   
                   <div class="col-top-right txt-r vw12 extranet"><a v-bind:href="$t('link_extranet')" target="_blank" >{{$t('extranet')}}</a><br /></div>
                   <div class="col-top-right txt-r vw12 langue"><a v-on:click="$emit('setLang','fr')">FR</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a v-on:click="$emit('setLang','en')">EN</a></div>
                  </div>
        
            <div class="wrapnav">
              <nav id="hamnav">
             
                  <label for="hamburger" class="hamburger-icon">
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                  </label>
                  <input type="checkbox" id="hamburger"/> 
    
                  <div class="main_menu vw12" id="hamitems">
                           <ul>
                            <!-- <a href="/company">{{$t('company')}}</a> -->
                            <li><router-link to="/company">{{$t('header.company')}}</router-link></li>
                            <li><router-link to="/application">{{$t('header.application')}}</router-link></li>
                            <li><router-link to="/product">{{$t('header.products')}}</router-link></li>
                            <li><router-link to="/ecological">{{$t('header.ecological')}}</router-link></li>
                            <li><router-link to="/contact">{{$t('header.contact_us')}}</router-link></li>
                        </ul>
                  </div>
                </nav>  
    </div>
    
          </div>
     </header>    
</template>


 <i18n src="../../public/Resources/I18N/Module.Header.json"></i18n> 
  
 <i18n>
  {
    "fr": {
      "extranet": "Connexion distributeur",
      "link_extranet" : "http://member.cmimulching.com/membreaccueil" 
    
    },
    "en": {
        "extranet": "Dealer login",
        "link_extranet" : "http://member.cmimulching.com/MemberHome" 
    }
  }
</i18n>

  <script>
    // @ is an alias to /src
    // msg="Welcome to Your Vue.js App"
    
    export default {
      name: 'header',
        mounted:
        function () {
  
  window.onscroll = function () { checkSticky(); };

  var navbar = document.getElementById("hamnav");
  var sticky = 0;
  if(navbar){
    sticky = navbar.offsetTop;
  }
   
  function checkSticky() {
    if(navbar){
    if (window.pageYOffset >= sticky) {
      navbar.classList.add("sticky")
    } else {
      navbar.classList.remove("sticky");
    }
      }
  } 


 // this.$nextTick(function () {  });
 
}
            ,
      data: () => ({
        
          }),
    }
    </script>
  