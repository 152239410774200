<template>
    <div id="cookie" >
        <div class="overlayCookie">
          <div class="cookieBox cookiePad">
            <div class="cookieTextPad">
              <h2 class="txt-c GeneralTitle" v-html="$t('title')"></h2>
              <div class="cookieText">
                <p class="txt-j" v-html="$t('generalText')"></p>
                <div class="cookieLink"><p @click="navigateToPolicy()" class="txt-r" v-html="$t('privacyPolicy')"></p></div>
              </div>
              <div class="mt-5">
                <div class="cookieText cookieLink lang">
                  <h3 style="cursor: pointer;" v-if="this.$i18n.locale != 'fr'" @click="switchLanguage()">FR</h3>
                  <h3 style="cursor: pointer;" v-if="this.$i18n.locale != 'en'" @click="switchLanguage()">EN</h3>                
                </div>
                <button class="btnCookie flt-r" @click="cookieChoice(1);" v-html="$t('allAccepted')"></button>
                <button class="btnCookie flt-r" @click="cookieChoice(3);" v-html="$t('choiceCookie')"></button>
              </div>
            </div>            
        </div>
        <div class="cookiePersonalise">
            <div class="">
              <div class="">
                <div><h2 class="txt-c GeneralTitle" v-html="$t('choiceCookie')"></h2></div>
                <div class="cookiePersonaliseInfo">     
                  <div><!--Essentiel-->
                    <div><h2 class="cookiePersionalisationTitle" v-html="$t('customizeEssentielTitle')"></h2></div>
                    <div><p class="cookiePersionalisationTexte txt-j" v-html="$t('customizeEssentielTexte')"></p></div>
                    <div><p class="cookiePersionalisationTexte f-bold txt-r"  v-html="$t('customizeEssentielTexte2')"></p></div>
                    <div class=""><hr></div>
                  </div>
                  <div><!--Performance-->
                    <div><h2 class="cookiePersionalisationTitle" v-html="$t('customizePerformanceTitle')"></h2></div>
                    <div><p class="cookiePersionalisationTexte txt-j" v-html="$t('customizePerformanceTexte')"></p></div>
                    <div class="switchContainer"><div class="switch off" @click="toggleSwitch('Performance')" :class="{ 'on': authorizationCookies['Performance'], 'off': !authorizationCookies['Performance'] }"><div class="slider"></div></div></div>
                    <div><hr></div>
                  </div>
                  <div> <!--Personnalisation-->
                    <div><h2 class="cookiePersionalisationTitle" v-html="$t('customizePersonnalisationTitle')"></h2></div>
                    <div><p class="cookiePersionalisationTexte txt-j" v-html="$t('customizePersonnalisationTexte')"></p></div>
                    <div class="switchContainer"><div class="switch off" @click="toggleSwitch('Personalization')" :class="{ 'on': authorizationCookies['Personalization'], 'off': !authorizationCookies['Personalization'] }"><div class="slider"></div></div></div>
                    <div><hr></div>
                  </div>
                  <div><!--pub-->
                    <div><h2 class="cookiePersionalisationTitle" v-html="$t('customizeAdvertiseTitle')"></h2></div>
                    <div><p class="cookiePersionalisationTexte txt-j" v-html="$t('customizeAdvertiseTexte')"></p></div>
                    <div class="switchContainer"><div class="switch off" @click="toggleSwitch('Advertising')" :class="{ 'on': authorizationCookies['Advertising'], 'off': !authorizationCookies['Advertising'] }"><div class="slider"></div></div></div>
                    <div><hr></div>
                  </div>
                  <div class="cookieLink"><p @click="navigateToPolicy()" class="txt-r"  v-html="$t('privacyPolicy')"></p></div>
                </div>
              </div>
              <div class="mt-5">
                <div class="cookiePad cookieLink lang">
                  <h3 style="cursor: pointer;" v-if="this.$i18n.locale != 'fr'" @click="switchLanguage()">FR</h3>
                  <h3 style="cursor: pointer;" v-if="this.$i18n.locale != 'en'" @click="switchLanguage()">EN</h3>                
                </div>
                <button class="btnCookie flt-r" @click="cookieChoice(1);" v-html="$t('allAccepted')"></button>
                <button class="btnCookie flt-r" @click="cookieChoice(2);" v-html="$t('Accepted')"></button>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>
<style scoped>
    @import '../../public/App_Themes/Main/Main.css'; 
    /*#region cookie*/

        .txt-c {text-align: center;}
        .txt-r {text-align: right;}
        .txt-j {text-align: justify;}
        .mt-5 {margin-top: 10px;}

        h3 {font-size: 24px;}
        .lang {display: inline-block; }
        .cookiePad {padding: 10px !important;}
        .cookieTextPad {padding: 20px 100px !important;}
        .GeneralTitle { font-family: Montserrat; font-size: 36px; padding: 20px;}
        .cookieTitle {font-family: Montserrat; font-weight: 700; font-size: 64px !important; height: 64px; padding: 15px 10px !important; text-align: center;}
        .cookiePersionalisationTitle { font-size: 24px; padding: 25px !important; font-family: sans-serif;}
        .cookiePersionalisationTexte{ font-size: 14px; padding: 10px 50px !important; font-family: sans-serif;}
        .cookieText {padding: 0px 200px !important;}
        .cookiePersonaliseInfo {padding: 0px 50px !important;}
        .cookieLink { color: rgb(var(--primary)) !important; text-decoration: none !important; cursor: pointer; font-family: Montserrat;}
        .cookieLink:hover { text-decoration: underline !important; }
        .btnCookie {padding: 10px !important; text-transform:uppercase; margin: 5px !important; border: 1px solid rgb(var(--primary)); color: rgb(var(--primary)) !important; cursor: pointer; border-radius: 5px;}
        .btnCookie:hover { background-color: rgb(var(--primary)) !important; color: rgb(var(--secondary)) !important; }
        .overlayCookie { display: none; position: fixed; width: 100%; height: 100%; z-index: 100000; background-color: rgba(000,000,000,0.3); }
        .cookieBox { display: none; position: fixed; bottom: 0; width: 100%; height: auto; background-color: white;}
        .cookiePersonalise { display: none; overflow-y:scroll; position: fixed; z-index: 100000; width: auto; max-width: 75%; left: 50%; transform: translate(-50%, 0%); height: 100%; max-height:100%; background-color: white;}
        .switchContainer {height: 30px;}
        .switch {display: inline-block; width: 50px; height: 20px; background-color: #ccc; border-radius: 15px; position: relative; float: right; cursor: pointer; }
    
        /* Styles pour la poignée (slider) */
        .slider { width: 20px; height: 20px; background-color: black; border-radius: 50%; position: absolute; transition: 0.2s; }
    
        /* Position de la poignée lorsque le v-switch est désactivé */
        .switch.off .slider { transform: translateX(0); }
        /* Position de la poignée lorsque le v-switch est activé */
        .switch.on {background-color: rgba(var(--primary),0.2);}
        .switch.on .slider { background-color: rgb(var(--primary)); transform: translateX(30px); }
        .flt-r {float: right;}
    /*#endregion */
    @media handheld, only screen and (max-width: 960px) {

    /*#region cookie*/
        .cookiePad {padding: 5px !important;}
        .cookieTextPad {padding: 10px 10px !important;}
        .cookieTitle {padding: 5px 0px !important;}
        .cookiePersionalisationTitle { font-size: 24px; padding: 10px !important; }
        .cookiePersionalisationTexte{ font-size: 14px; padding: 5px 10px !important;}
        .cookieText {padding: 0px 10px !important;}
        .cookiePersonaliseInfo {padding: 0px 10px !important;}
        .cookiePersonalise {  max-width: 100%; left: 0%; transform: translate(0%, 0);}
        .GeneralTitle {font-size: 32px; margin-top: 70px !important;}
    /*#endregion */
  }
</style>
<script>
  export default {
    name: 'Cookie',
    data: () => ({
        element: null,
        authorizationCookies: {
            Essential: true,
            Performance: false,
            Personalization: false,
            Advertising: false,
        },
        ListLang:['fr','en'],
        translations:{
          fr:{
            "title":"Nous utilisons des témoins",
            "generalText":"Votre vie privée est une priorité pour nous. Nous utilisons des témoins (cookies) et d'autres technologies similaires pour personnaliser nos contenus et améliorer votre expérience en ligne. Certains de ces témoins (cookies) peuvent être collectés avec votre consentement préalable.",
            "privacyPolicy":"Politique de confidentialité",
            "allAccepted":"Tout Accepter",
            "Accepted":"Accepter",
            "choiceCookie":"Personalisé vos témoins",
            "customizeEssentielTitle":"Témoins nécessaires",
            "customizeEssentielTexte":"Ces témoins vous permettent d’apprécier toutes les fonctionnalités de notre site et d’y naviguer de manière optimale et sécuritaire. Ils nous servent aussi à vous demander votre opinion et à évaluer votre satisfaction.",
            "customizeEssentielTexte2":"Ces témoins sont obligatoires.",
            "customizePerformanceTitle":"Témoins de performances",
            "customizePerformanceTexte":"Grâce à l'emploi de ces témoins (cookies), nous avons la possibilité d'examiner la manière dont nos sites sont utilisés en vue d'améliorer leur performance et leur fonctionnement.",
            "customizePersonnalisationTitle":"Témoins de personnalisation",
            "customizePersonnalisationTexte":"Grâce à ces témoins (cookies), nous sommes en mesure de retenir vos préférences et d'ajuster les contenus proposés en fonction de votre historique de navigation et de vos choix précédents.",
            "customizeAdvertiseTitle":"Témoins de publicité ciblée",
            "customizeAdvertiseTexte":"Ces témoins (cookies) contribuent à restreindre la fréquence d'affichage des publicités, à adapter nos offres et services à vos centres d'intérêt, à évaluer l'efficacité de campagnes publicitaires, entre autres usages. Il est possible que nous partagions ces données avec nos partenaires."
          },
          en:{
            "title":"We use cookies",
            "generalText":"Your privacy is a priority for us. We use cookies and other similar technologies to personalize our content and enhance your online experience. Some of these cookies may be collected with your prior consent.",
            "privacyPolicy":"Privacy Policy",
            "allAccepted":"Accept All",
            "Accepted":"Accept",
            "choiceCookie":"Customize your cookies",
            "customizeEssentielTitle":"Essential cookies",
            "customizeEssentielTexte":"These cookies allow you to enjoy all the features of our site and navigate it optimally and securely. They also help us gather your feedback and assess your satisfaction.",
            "customizeEssentielTexte2":"These cookies are mandatory.",
            "customizePerformanceTitle":"Performance cookies",
            "customizePerformanceTexte":"Through the use of these cookies, we have the ability to analyze how our sites are used to enhance their performance and functionality.",
            "customizePersonnalisationTitle":"Customization cookies",
            "customizePersonnalisationTexte":"Thanks to these cookies, we can remember your preferences and tailor the content offered based on your browsing history and previous choices.",
            "customizeAdvertiseTitle":"Targeted advertising cookies",
            "customizeAdvertiseTexte":"These cookies help limit the frequency of ad displays, tailor our offers and services to your interests, evaluate the effectiveness of advertising campaigns, among other uses. It's possible that we may share this data with our partners.",
          }
        },
      }),
    mounted: async function () {
        $App.event.$on("openCookie", this.openWindow);
        this.$i18n.mergeLocaleMessage('fr', this.translations.fr);
        this.$i18n.mergeLocaleMessage('en', this.translations.en);
        this.element = document.getElementById("cookie");
        let authorizations = JSON.parse(localStorage.getItem("authorizationCookies"));
        if(authorizations){
            this.authorizationCookies = authorizations;
        } else { 
            this.element.getElementsByClassName('cookieBox')[0].style.display = 'block';
            this.element.getElementsByClassName('overlayCookie')[0].style.display = 'block';
        }
    },
    methods:{
        cookieChoice: function (choiceMade){
            if(choiceMade == 1){
                this.ShowCookieWindow = false;
                this.element.getElementsByClassName('cookieBox')[0].style.display = 'none';
                this.element.getElementsByClassName('overlayCookie')[0].style.display = 'none';
                
                this.authorizationCookies['Performance'] = true;
                this.authorizationCookies['Personalization'] = true;
                this.authorizationCookies['Advertising'] = true;
                localStorage.setItem('authorizationCookies', JSON.stringify(this.authorizationCookies));
                window.location.reload(); 
            }
            else if(choiceMade == 2){
                this.element.getElementsByClassName('cookiePersonalise')[0].style.display = 'none';
                this.element.getElementsByClassName('overlayCookie')[0].style.display = 'none';
                localStorage.setItem('authorizationCookies', JSON.stringify(this.authorizationCookies));
                window.location.reload(); 
            }
            else{ 
                this.element.getElementsByClassName('cookieBox')[0].style.display = 'none';
                this.element.getElementsByClassName('cookiePersonalise')[0].style.display = 'block';
            }
        },
        toggleSwitch: function(index) {
            this.authorizationCookies[index] = !this.authorizationCookies[index];
        },
        navigateToPolicy: function(){
            const element = document.getElementById("cookie");
            element.getElementsByClassName('cookieBox')[0].style.display = 'none';
            element.getElementsByClassName('overlayCookie')[0].style.display = 'none';
            
            localStorage.setItem('authorizationCookies', JSON.stringify(this.authorizationCookies));
            this.$router.push('/policy');
        },
        openWindow: function(){
            this.element.getElementsByClassName('cookieBox')[0].style.display = 'block';
            this.element.getElementsByClassName('overlayCookie')[0].style.display = 'block';
        },
        switchLanguage: function () { 
            this.$i18n.locale = this.currentLang() === 'en' ? 'fr' : 'en';
            localStorage.setItem('Lang', this.$i18n.locale);
        },
        currentLang: function() {
            this.setLang();
            return this.$i18n.locale;
        },
        setLang: function() {
            let lang = localStorage.getItem("Lang");
            if(lang){this.$i18n.locale = lang;}
        },
    },
    component: {

    }
  }
</script>