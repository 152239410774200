<template>
  <div id="app" class="">

    <!-- <headerMain @setLang="setLang" /> -->
    <Cookie/>
    <router-view/>
    <footerMain />
    <!-- <div class="">
      <div class="txt-r vw18 langue2">
        <a @click="$i18n.locale = 'fr'" >FR</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a @click="$i18n.locale = 'en'">EN</a>
      </div>
    </div>   -->

  </div>
</template>
<!-- 
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
-->

<script>
  // @ is an alias to /src
  // msg="Welcome to Your Vue.js App"
  // import headerMain from "@/components/Header.vue";
  import Cookie from '@/components/cookie.vue';
  import footerMain from "@/components/Footer.vue";

  export default {
    name: 'app',
    data: () => ({
      
        }),
    methods: {
      // setLang: function (lang) {
      //   this.$i18n.locale = lang;
      //   }
    },
        components: {
            // "headerMain": headerMain,
            Cookie,
            "footerMain": footerMain
        }
  }
  </script>