
<template>
    <footer>
        <div class="">
            <div class="col-1-1 footer_top">
              <div class="grid grid-pad">
                  <div class="col-1-7 hide-on-mobile logo_footer"><a href="/home" ><img v-bind:src="$t('logo')" alt="" title="" width="110" /></a></div>
                  <div class="col-1-2 mobile-col-1-1 adress_footer ">
                    <p v-html="$t('adress')"></p><br />
                    <a href="tel:819-397-2288">819-397-2288</a>
                    <div class="col-1-1 baseLine">
                      <p><a @click="openCookie()" v-html="$t('cookie')"></a></p>
                      <p><router-link to="policy" v-html="$t('policy')"></router-link></p>
                    </div>
                  </div>
                  <div class="col-down">&nbsp;</div>
                  <div class="col-1-5 hide-on-mobile txt-r footersocial"> <a class="social" href="https://www.linkedin.com/company/cmi-mulching/" target="_blank"><img  src="/Resources/Images/in.png" alt="" title="" width="21" /></a>
                    <a class="social"  href="https://www.instagram.com/explore/tags/cmimulching/?hl=fr-ca" target="_blank"><img src="/Resources/Images/instagramme.png" alt="" title="" width="21" /></a> 
                    <a href="https://www.facebook.com/CMITHEMULCHINGSPECIALISTS/" target="_blank"><img src="/Resources/Images/facebook.png" alt="" title="" width="21" /></a> </div>
            </div>
          </div>
          <div class="col-1-1 hide-on-mobile footer_down">
              <div class="grid grid-pad">
            <!-- <div class="col-1-2 "><p v-html="$t('copyright')"></p> <script type="text/JavaScript"> document.write(new Date().getFullYear());</script> </div> -->
            <div class="col-2-3 baseLine" >
              <p v-html="$t('copyright')"></p>
            </div>
            <div class="col-1-3 txt-r">{{$t('realise')}}<a href="https://www.wx3data.com" target="_blank" >WX3Data</a></div>
           </div>
          </div>
        </div>
        </footer>    
  </template>
  
  <i18n src="../../public/Resources/I18N/Module.Footer.json"></i18n>
  <style scoped>
  .baseLine p {display: inline-block; padding-right: 10px;}
  .baseLine a {cursor: pointer;}
  .footer_top a { color: #FFF; text-decoration: none; cursor: pointer; }
  .footer_top a:hover { text-decoration: underline; }
  </style>

  <script>
    // @ is an alias to /src
    // msg="Welcome to Your Vue.js App"

    export default {
      name: 'Footer',
      data: () => ({
      }),
      methods: {
        openCookie: function(){
            $App.event.$emit('openCookie');
        }
    },
    }
  </script>
 -